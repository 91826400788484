<template lang="pug">
v-menu(v-model="menuShowing")
  template(v-slot:activator="{ on }")
    v-icon(v-on="on" small).copy-arrow mdi-menu-down
  div.select-menu
    v-progress-circular(indeterminate size=34 color="warning" v-show="loading")
    v-list
      v-list-item(
        v-for="(option, index) in menuOptions" :key="index" 
        @click="selectOption(option)"
        :class="{'active': isOptionActive(option)}"
      ) 
        template(v-if="itemText") {{ option[itemText] }}
        template(v-else) {{ option }}
</template>

<script>
export default {
  props: {
    options: Array,
    itemText: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: ''
    },
    getOptions: {
      type: Function
    },
    selectedOptions: {
      type: null
    }
  },

  data: () => ({
    menuShowing : false,
    menuOptions: [],
    loading: false
  }),

  mounted() {
  },

  methods: {
    setMenuOptions() {
      if(this.getOptions) {
        this.loadOptions()
        return
      }
      this.menuOptions = [...this.options]
    },

    async loadOptions() {
      try {
        this.loading = true
        this.menuOptions = await this.getOptions({cache: true})
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
      
    },

    selectOption(option) {
      let _option = this.itemValue ? option[this.itemValue] : option
      this.$emit('change', _option)
    },

    isOptionActive(option) {
      let optionValue = this.itemValue ? option[this.itemValue] : option
      let item = this.selectedOptions.find(selected => {
        if(selected == optionValue) return selected
      })
      if(item) return true
      return false
    }
  },

  watch: {
    menuShowing(isShowing) {
      if(isShowing) this.setMenuOptions()
    }
  }
}
</script>

<style lang="scss">
.select-menu {
  max-height: 300px;
  overflow: auto;
}

.v-list .v-list-item.active {
  font-weight: bold;
}
</style>